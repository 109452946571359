.calculationContainer {
    display: flex;
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
}

.calFirstBox {
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 500px;
}

.calImage {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

.calSecondBox {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 500px;
}

.medalStyle {
    padding-top: 15%;
}

.smallTitle {
    font-size: 18px;
    font-weight: 600;
    color: #00e500
}

.biggerTitle {
    color: #00e500;
    font-weight: 700;
    font-size: 44px;
    margin-top: -20px;
    line-height: 40px;
}

.basicText {
    font-weight: 400;
    font-size: 14px;
    color: #979797;
    margin-top: -20px;
    padding-right: 10%;
}

@media (max-width:1100px) {
    .calculationContainer {
        display: flex;
        flex: 1;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column;
    }

    .calFirstBox {
        flex: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 60px;
        min-height: auto;
    }

    .calImage {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }

    .calSecondBox {
        flex: 1;
        justify-content: center;
        align-items: center;
        padding-top: 0px;
        padding-bottom: 60px;
        min-height: auto;
        text-align: center;
    }

    .medalStyle {
        padding-top: 0;
    }

    .smallTitle {
        font-size: 18px;
        font-weight: 600;
        color: #00e500
    }

    .biggerTitle {
        color: #00e500;
        font-weight: 700;
        font-size: 38px;
        margin-top: -20px;
        line-height: 40px;
    }

    .basicText {
        font-weight: 400;
        font-size: 14px;
        color: #979797;
        margin-top: -20px;
        padding-right: 0;
    }
}