.motivationContainer {
    display: flex;
    flex: 1;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #f9f9f9;
    padding-left: 10%;
    padding-right: 10%;
}

.motFirstBox {
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 128px;
    padding-bottom: 128px;
    min-height: 500px;
}

.motImage {
    width: 125%;
}

.motSecondBox {
    flex: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
    min-height: 500px;
}

.motTextBox {
    padding-top: 15%;
}

@media (max-width:1100px) {
    .motivationContainer {
        display: flex;
        flex: 1;
        padding-left: 15px;
        padding-right: 15px;
        flex-direction: column-reverse;
    }

    .motFirstBox {
        flex: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding-top: 0px;
        padding-bottom: 100px;
        min-height: auto;
    }

    .motImage {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }

    .motSecondBox {
        flex: 1;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
        padding-bottom: 60px;
        min-height: auto;
        text-align: center;
    }

    .medalStyle {
        padding-top: 0;
    }

    .motTextBox {
        padding-top: 5%;
    }

    .smallTitle {
        font-size: 18px;
        font-weight: 600;
        color: #00e500
    }

    .biggerTitle {
        color: #00e500;
        font-weight: 700;
        font-size: 38px;
        margin-top: -20px;
        line-height: 40px;
    }

    .basicText {
        font-weight: 400;
        font-size: 14px;
        color: #979797;
        margin-top: -20px;
        padding-right: 0;
    }
}