.firstImageContainer {
    border-top: white 0.5px solid;
    display: flex;
    flex: 1;
}

.firstSliderBox {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-top: 13%;
    padding-bottom: 10%;
}

.secondSliderBox {
    display: flex;
    flex: 1;
    margin-top: 2%;
    justify-content: center;
    align-items: center;
}

.sliderScreenImage {
    width: 90%;
    height: auto;
}

.downloadLink {
    display: flex;
    flex-direction: row;
}

.sliderTextBox {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding-left: 10%;
}

.sliderTitle {
    font-weight: 700;
    color: white;
    font-size: 36px;
}

.sliderTypicalTitle {
    font-weight: 700;
    color: white;
    font-size: 44px;
    margin-top: -75px;
}

.sliderText {
    color: white;
    font-size: 16px;
    margin-top: -20px;
}

.downloadLink {
    margin-top: 36px;
}

.googleLink {
    padding-right: 4px;
}

.appStoreLink {
    padding-left: 4px;
}

@media (max-width:1100px) {
    .firstImageContainer {
        border-top: white 0.5px solid;
        display: flex;
        flex: 1;
        flex-direction: column-reverse;
    }

    .firstSliderBox {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        padding-top: 5%;
    }

    .secondSliderBox {
        display: flex;
        flex: 1;
        margin-top: 5%;
        justify-content: center;
        align-items: center;
    }

    .sliderTextBox {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding-left: 5%;
    }

    .sliderTitle {
        font-weight: 700;
        color: white;
        font-size: 24px;
        text-align: center;
    }

    .sliderTypicalTitle {
        font-weight: 700;
        color: white;
        font-size: 30px;
        margin-top: -60px;
        text-align: center;
    }

    .sliderText {
        color: white;
        font-size: 14px;
        padding: 15px 15px 15px 15px;
        margin-top: -20px;
        text-align: center;
    }

    .downloadLink {
        margin-top: 36px;
        display: flex;
        flex-direction: column;
    }

    .googleLink {
        padding-right: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .appStoreLink {
        padding-left: 0px;
        margin-left: auto;
        margin-right: auto;
    }

    .sliderScreenImage {
        width: 50%;
        height: auto;
    }

    .brclass {
        display: none;
    }
}