.navbar {
    min-height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 68px;
    background-color: #00e300;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 60px;
}

.nav-branding {
    font-size: 20px;
    font-weight: 700;
    color: white;
    text-decoration: none;
    letter-spacing: -0.5px;
}

.nav-item {
    list-style: none;
}

.nav-link {
    color: white;
    text-decoration: none;
    transition: 0.8s ease;
    font-size: 14px;
    font-weight: 600;
}

.nav-link:hover {
    color: #33444c;
}

.hamburger {
    display: none;
    cursor: pointer;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: white;
}

@media(max-width:1100px) {
    .navbar {
        padding: 0 24px;
    }

    .hamburger {
        display: block;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        transform: translateY(-8px) rotate(-45deg);
    }

    .nav-menu {
        position: fixed;
        left: -115%;
        top: 70px;
        gap: 0;
        flex-direction: column;
        background-color: #00e300;
        width: 100%;
        transition: 0.3s;
        justify-content: left;
        align-items: flex-start;
        z-index: 5000;

    }

    .nav-item {
        margin: 14px 0px;
    }

    .nav-menu.active {
        left: 0;
        position: absolute;
    }
}