.youCanContainer {
    background-image: url('/src/images/yesYouCan.png');
    background-position: center center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    min-height: 600px;
    margin-top: -20px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.paddingArea {
    padding-top: 10%;
    padding-bottom: 10%;
}

.youCanTitle {
    color: white;
    font-weight: 700;
    font-size: 38px
}

.youCanText {
    color: white;
    font-size: 14px;
    padding-left: 15%;
    padding-right: 15%;
}